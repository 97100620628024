import React from "react";
import { Button, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import styled from "styled-components";
import { navigate } from "gatsby-link";

const StyledTextField = styled(TextField)`
  margin: 10px 0 !important;
`;

const StyledButton = styled(Button)`
  margin: 10px 0 !important;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 60px auto 80px auto;
  max-width: 500px;
`;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const ContactForm = () => {
  const [state, setState] = React.useState({});

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  return (
    <Form
      method="post"
      action="/thanks/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
      name="contact-form"
    >
      <input type="hidden" name="form-name" value="contact-form" />
      <p hidden>
        <label>
          Don’t fill this out:{" "}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      <StyledTextField
        id="outlined-basic"
        name="email-address"
        label="Email address"
        variant="outlined"
        onChange={handleChange}
      />
      <StyledTextField
        id="outlined-basic"
        name="subject"
        label="Subject"
        variant="outlined"
        onChange={handleChange}
      />
      <StyledTextField
        id="outlined-textarea"
        name="message"
        label="Message"
        placeholder="Message..."
        multiline
        rows={6}
        variant="outlined"
        onChange={handleChange}
      />
      <StyledButton
        type="submit"
        variant="contained"
        color="primary"
        endIcon={<SendIcon />}
      >
        Send
      </StyledButton>
    </Form>
  );
};

export default ContactForm;
