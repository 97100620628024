import * as React from "react";
import styled from "styled-components";
import ContactForm from "../components/contact-form/contact-form";
import Layout from "../components/layout/layout";
import SEO from "../components/seo/seo";
import SubTitle from "../components/sub-title/sub-title";
import Title from "../components/title/title";

const Main = styled.main`
  margin-top: 40px;
`;

const Contact = () => {
  return (
    <Layout>
      <SEO
        title={`Contact me - Ben Pearey`}
        description={`Ask a question`}
      ></SEO>
      <Main>
        <Title>Contact me</Title>
        <SubTitle>
          If you would like to find out more about my photography or photographs
          please contact me below
        </SubTitle>
        <ContactForm />
      </Main>
    </Layout>
  );
};

export default Contact;
